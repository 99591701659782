import { getOrCreateDataObject } from 'o365-dataobject';

export const defaultNodeData = {
    displayField: 'NameAndTitle',
    HeaderName: 'Name And Title',
    type: 'hierarchy',
    parentField:"Parent_ID",
    requireParents: true,
    idField:"ID",
    idPathField: "IdPath",
    nodesCountField:"HasSubNodes",
};

export const dsTree = getOrCreateDataObject({
  id: 'o_dsOrgUnitsTree',
  disableLayouts: false,
  viewName: 'sviw_System_OrgUnitsTree',
  distinctRows: false,
  maxRecords: -1,
  selectFirstRowOnLoad: true,
  enableProperties: true,
  fields: [
    { name: 'ID', type: 'number' },
    { name: 'IdPath', type: 'string' },
    { name: 'OrgUnit', type: 'string' },
    { name: 'Closed', type: 'date' },
    { name: 'Updated', type: 'date' },
    // { name: 'Responsible', type: 'string' },
    { name: 'IsClosed', type: 'boolean' },
    { name: 'Name', type: 'string', sortOrder: 1, sortDirection: 'asc' },
    { name: 'Title', type: 'string' },
    { name: 'NamePrefix', type: 'string' },
    { name: 'Domain_ID', type: 'number' },
    { name: 'Level', type: 'number' },
    { name: 'UnitType', type: 'string' },
    { name: 'Company', type: 'string' },
    { name: 'NamePath', type: 'string' },
    { name: 'Parent', type: 'string' },
    { name: 'Parent_ID', type: 'number' },
    { name: 'AccessIdPath', type: 'string' },
    { name: 'NameAndTitle', type: 'string' },
    { name: 'HasSubNodes', type: 'boolean' },
    { name: 'AllowAsContext', type: 'boolean' },
  ],
});

export function getTreeDataSource(whereClause: string){
    dsTree.recordSource.whereClause = whereClause;
    return dsTree;
}

 export async function setTreeCurrentIndex(id:any){

    let node = dsTree.nodeData.configurations[0].findNodeById(id)

    if ( node !== null && node.isLoading  ) {
        await node.loadingPromise;
    }
    dsTree.setCurrentIndex(node.index);
}

export function doSearch(filterString: string){
    if(filterString != ''){
        dsTree.recordSource.filterString =`[SearchColumn] LIKE '%${filterString}%'`;
        dsTree.load();
    }
    else{
        dsTree.recordSource.filterString = "";
        dsTree.load();
    }
}

export function createWhereClause(filterString: string) {
    let whereClauseArr: Array<string> = [];
    if (filterString && filterString.trim().length > 0) {
        whereClauseArr.push(filterString);
    }
    const whereClause = whereClauseArr.join(' AND ');

    return whereClause
}
